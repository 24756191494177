import { useState, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import trackSelectedSalon from 'lib/utils/trackSelectedSalon';
import CloseContainer from 'components/shared/close-container';
import SearchIcon from 'components/shared/searchIcon';
import { useSettings } from 'contexts/SettingContext';
import { useUI } from 'contexts/UIContext';
import Icon from 'components/shared/icon';
import {
  setStorageSelectedSalonNo,
  setStorageCustomerSalonName,
  setStorageCustomerSalonNo,
  getCountryByLocale,
} from 'lib/shopify';
import { get, keys } from 'lib/utils/localStorage';
import { useCustomerSalon } from 'hooks';
import styles from './salonSelectorModal.module.scss';

export default function SalonSelectorModal({ globalModules, locale, customCallback }) {
  // Check if there's a salon in localstorage already to preselect in the selector
  const [selectedSalon, setSelectedSalon] = useState(null);
  const { salons, currentSalon, setCurrentSalon, setSalonManuallyDeleted } = useSettings();
  const { setCustomerSalon } = useCustomerSalon(locale);

  const [filteredSalons, setFilteredSalons] = useState(salons);
  const [searchQuery, setSearchQuery] = useState('');
  const [myLocation, setMyLocation] = useState(false);
  const [loading, setLoading] = useState(false);
  const { displaySalonSelector, closeSalonSelector } = useUI();
  const router = useRouter();
  const { salonselector } = globalModules;
  // const FIRST_CHUNK_LIMIT = 700;

  const getDistance = (a, b) =>
    Math.sqrt((a.latitude - b.latitude) ** 2 + (a.longitude - b.longitude) ** 2);

  const ambassadorPlusAffiliationSalons = useMemo(() => {
    const compareSalons = (a, b) => {
      if (!myLocation.latitude) return a.salonName.trim().localeCompare(b.salonName.trim()); // if no location, sort by name
      const distanceA = getDistance(a.position, myLocation);
      const distanceB = getDistance(b.position, myLocation);
      return distanceA - distanceB;
    };

    const country = getCountryByLocale(locale);
    const ambassadorSalons = [...salons]
      .filter(
        salon =>
          (salon.salonLoyalty || salon.client100) &&
          salon.salonNo !== currentSalon?.salonNo &&
          salon.country === country
      )
      .sort((a, b) => compareSalons(a, b));
    /** If the selectedSalon is a non-ambassador salon, put that as the first element, afterwards spread the rest */
    if (currentSalon) return [currentSalon, ...ambassadorSalons];
    return [...ambassadorSalons];
  }, [salons, currentSalon, locale, myLocation]);

  // useEffect(() => {
  //   const salonsWithDistance = ambassadorPlusAffiliationSalons.map(salon => {
  //     const distance = getDistance(salon.position, myLocation);
  //     return { name: salon.salonName, distance };
  //   });

  //   console.log('salonsWithDistance', salonsWithDistance);
  // }, [ambassadorPlusAffiliationSalons, myLocation]);

  useEffect(() => {
    const fetchLocationThroughIP = () => {
      fetch('/api/geolocation')
        .then(res => res.json())
        .then(location => {
          setLoading(false);
          setMyLocation({
            latitude: location.lat,
            longitude: location.lon,
          });
        })
        .catch(err => console.error(err));
    };

    const getGeolocation = () => {
      // Try to get location by the Browser API
      navigator.geolocation.getCurrentPosition(
        position => {
          setLoading(false);
          setMyLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        () => {
          // If the browser API geolocation fails, use the IP geolocation
          fetchLocationThroughIP();
        }
      );
    };

    if (displaySalonSelector && !myLocation) {
      setLoading(true);
      getGeolocation();
    }
  }, [displaySalonSelector]);

  useEffect(() => {
    const email = get(keys.LOGGED_IN_CUSTOMER);

    if (email !== 'null') {
      setCustomerSalon(email, currentSalon?.salonName || '');
    }
    setSelectedSalon(currentSalon);
  }, [currentSalon]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchQuery !== '')
        setFilteredSalons(
          ambassadorPlusAffiliationSalons.filter(
            salon =>
              salon.salonName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              salon.AddressLine1?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              salon.AddressLine2?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              salon.city?.toLowerCase().includes(searchQuery.toLowerCase())
          )
        );
      else setFilteredSalons(ambassadorPlusAffiliationSalons);
    }, 300);

    return () => clearTimeout(timeout);
  }, [searchQuery, ambassadorPlusAffiliationSalons]);

  const confirmSelection = () => {
    if (!selectedSalon) return;
    setSalonManuallyDeleted(false);
    setStorageSelectedSalonNo(locale, selectedSalon.salonNo);
    setStorageCustomerSalonName(locale, selectedSalon.salonName);
    setStorageCustomerSalonNo(locale, selectedSalon.salonNo);
    setCurrentSalon(selectedSalon);
    trackSelectedSalon({
      salonNo: selectedSalon.salonNo,
      eventName: 'select_salon',
      location: { action: router },
    });
    closeSalonSelector();
    if (customCallback) {
      customCallback(selectedSalon);
    }
  };

  if (!displaySalonSelector) return null;

  return (
    <div className={styles.modalShadow}>
      <div className={styles.salonSelectorModal}>
        <button className={styles.closeButton} type="button" onClick={closeSalonSelector}>
          <CloseContainer width="1.2rem" height="1.2rem" />
        </button>
        <p className={styles.selectSalonText}>{salonselector.selectAmbassadorSalon.text}</p>
        <p className={styles.salonChoiceDescription}>{salonselector.salonChoiceDescription.text}</p>
        <div className={styles.searchBarWrapper}>
          <SearchIcon />
          <input
            className={styles.searchBar}
            type="text"
            value={searchQuery}
            placeholder={salonselector.searchPlaceholder.text}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
        <div className={styles.optionsView}>
          {loading && <Icon className={styles.loadingIcon} type="loading" />}

          {!loading && (
            <div className={styles.salonOptions}>
              {filteredSalons.map(salon => (
                <SalonOption
                  salon={salon}
                  key={salon.salonNo}
                  selected={selectedSalon?.salonName === salon.salonName}
                  setSelected={() => setSelectedSalon(salon)}
                />
              ))}
            </div>
          )}
        </div>
        <div className={styles.buttonsSection}>
          <button className={styles.confirmChoice} type="button" onClick={confirmSelection}>
            {salonselector.confirmChoice.text}
          </button>
          <button className={styles.cancelAction} type="button" onClick={closeSalonSelector}>
            {salonselector.cancelSelection.text}
          </button>
        </div>
      </div>
    </div>
  );
}

/** A singular salon option presented in the salon selector modal */
const SalonOption = ({ salon, selected, setSelected }) => (
  <div
    className={styles.salonOption}
    tabIndex={0}
    role="option"
    aria-selected={selected}
    onClick={setSelected}
    onKeyDown={setSelected}
  >
    <input className={styles.salonCheckbox} type="radio" checked={selected} onChange={() => {}} />
    <p className={styles.salonName}>{salon.salonName}</p>
  </div>
);

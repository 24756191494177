import { useUpdateItemQuantity } from 'hooks';
import Icon from 'components/shared/icon';
import Image from 'components/shared/image';
import SiteLink from 'components/shared/sitelink/sitelink';
import { useRouter } from 'next/router';
import { addLocaleToHref } from 'lib/utils/helpers';
import classNames from 'classnames';
import { useSettings } from 'contexts/SettingContext';
import BackInStockNotifier from 'components/pages/product/backInStockNotifier';
import { useEffect, useState } from 'react';
import styles from './outOfStockPopup.module.scss';

const OutOfStockPopup = ({ globalModules, lineItems, closeModal, locale, cart, startCheckout }) => {
  const router = useRouter();
  const updateItemQuantity = useUpdateItemQuantity();
  const { removeProduct } = useSettings();
  const GIDs = lineItems?.map(({ node }) => node?.variant?.id);

  const [showContinue, setShowContinue] = useState(false);

  const { message, buttonShopAnotherLink, buttonContinueCheckoutText } =
    globalModules?.outofstockcartpopup || {};

  const { emailWhenAvailable } = globalModules.productpage || {};

  const {
    freeItem,
    amountOfProductsToGetFreeItem,
    promoCollectionName,
    shopifyTagsToExcludeFromPromo,
  } = globalModules?.secondpartsettings || {};

  if (lineItems?.length) {
    document.body.style.overflow = 'hidden';
  }

  const getNewQuantity = (id, difference) => {
    const currentQuantity =
      cart.lineItems?.edges?.find(item => item.node.variant.id === id)?.node?.quantity || 0;

    let newQuantity = currentQuantity - difference;
    if (newQuantity < 0) newQuantity = 0;

    return newQuantity;
  };

  const getFreeItemsQuantity = (relatedProducts, mainProductQuantityDifference) => {
    if (!relatedProducts) return [];
    const parsedRelatedProducts = JSON.parse(relatedProducts);

    const newFreeItems = parsedRelatedProducts.map(id => ({
      variantId: id,
      quantity: getNewQuantity(id, mainProductQuantityDifference),
    }));

    return newFreeItems;
  };

  const handleRemoveLineItems = async items => {
    const newItemsWithQuantity = items.reduce((acc, { node: item }) => {
      acc.push({
        variantId: item?.variant?.id,
        quantity: 0,
      });

      const relatedProducts = item?.variant?.product?.relatedProducts?.value;
      if (relatedProducts) {
        acc.push(...getFreeItemsQuantity(relatedProducts, item?.quantity));
      }

      return acc;
    }, []);

    items.forEach(({ node: item }) => {
      removeProduct(item?.variant?.product?.id);
    });

    await updateItemQuantity(newItemsWithQuantity, freeItem?.product?.shopifyData, {
      amountOfProductsToGetFreeItem,
      promoCollectionName,
      shopifyTagsToExcludeFromPromo,
    });
  };

  const handleClose = () => {
    document.body.style.overflow = 'auto';
    closeModal();
  };

  useEffect(() => {
    // if there are other lineItems in the card, show continue button
    const otherLineItems = cart?.lineItems?.edges?.filter(
      item =>
        !lineItems?.find(({ node }) => node?.variant?.id === item?.node?.variant?.id) &&
        !item?.node?.variant?.product?.tags?.includes('free-item')
    );

    if (otherLineItems?.length) {
      setShowContinue(true);
    }
    if (lineItems.length) {
      handleRemoveLineItems(lineItems);
    }
  }, [lineItems]);

  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <button
          type="button"
          className={styles.closeBtn}
          onClick={() => {
            handleClose();
          }}
        >
          <Icon type="close" />
        </button>
        <div className={styles.message}>{message?.text}</div>
        <div className={styles.lineItems}>
          {lineItems?.map(lineItem => (
            <div className={styles.productInfo}>
              <div className={styles.itemImage}>
                <Image src={lineItem?.node?.variant?.image} layout="fill" />
              </div>
              <span className={styles.productTitle}>{lineItem?.node?.title}</span>
            </div>
          ))}
        </div>
        <BackInStockNotifier
          locale={locale}
          text={emailWhenAvailable.fullRichText}
          successText={emailWhenAvailable.text}
          submitText={emailWhenAvailable.multipleTexts[0]}
          productIds={GIDs}
          className={styles.notifier}
        />

        <div className={styles.buttons}>
          {showContinue && (
            <button type="button" className={styles.buttonBlack} onClick={() => startCheckout()}>
              {buttonContinueCheckoutText.text}
            </button>
          )}
          <SiteLink
            locale={buttonShopAnotherLink?.locale}
            className={classNames(styles.button, styles.linkButton)}
            href={addLocaleToHref(buttonShopAnotherLink?.link?.slug, locale, router)}
          >
            {buttonShopAnotherLink?.link?.title}
          </SiteLink>
        </div>
      </div>
    </div>
  );
};

export default OutOfStockPopup;

import { setStorageSelectedSalonNo, setStorageCustomerSalonNo } from './index';

export const addCheckoutAttributes = async (checkoutId, checkoutAttributes, locale) => {
  const response = await fetch('/api/shopify/checkout/add-attributes', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ checkoutId, input: checkoutAttributes.input, locale }),
  });

  if (response.status === 200) {
    // set(keys.SELECTED_SALON_NO, checkoutAttributes.input.customAttributes[0].value);
    // set(keys.CUSTOMER_SALON_NO, checkoutAttributes.input.customAttributes[1].value);

    setStorageSelectedSalonNo(locale, checkoutAttributes.input.customAttributes[0].value);
    setStorageCustomerSalonNo(locale, checkoutAttributes.input.customAttributes[1].value);
    return true;
  }

  return response.statusText;
};

export default addCheckoutAttributes;

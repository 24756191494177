import Image from 'components/shared/image';
import SiteLink from 'components/shared/sitelink';
import cn from 'classnames';
import useProductRemoveFromCartTracking from 'hooks/tracking/useProductRemoveFromCartTracking';
import useProductAddToCartTracking from 'hooks/tracking/useProductAddToCartTracking';
import { useSettings } from 'contexts/SettingContext';
import { addLocaleToHref } from 'lib/utils/helpers';
import { useUpdateItemQuantity, useUI, useWishlist, useLocalWishlist } from 'hooks';
import { useRouter } from 'next/router';
import { parseShopifyGid, getShopifyGid } from 'lib/shopify/shopifyGid';
import { formatPriceByStoreLocale } from 'lib/shopify';
import { useState, useEffect } from 'react';
import { mutate } from 'swr';
import UnitPrice from 'components/shared/unit-price/unitPrice';
import ItemCounter from '../../../pages/product/itemCounter';
import { useIsMobile } from '../../../../hooks/utils/useIsMobile';
import styles from './lineItem.module.scss';

const LineItem = ({
  locale,
  lineItem,
  index,
  contentfulData,
  cart,
  globalModules,
  isCartPopup,
}) => {
  const { addToWishlist } = useWishlist();
  const { localWishlist } = useLocalWishlist();

  useEffect(() => {
    // if lineitem fas no variant (became draft in shopify), revalidate swr cart
    if (!lineItem.variant) {
      mutate('/api/shopify/checkout/request/');
    }
  }, [lineItem.variant]);

  const router = useRouter();
  const updateItemQuantity = useUpdateItemQuantity();
  const isMobile = useIsMobile();
  const { removeProduct, disableFreeItem, trackedItemsSelectList } = useSettings();
  const outOfStock = lineItem?.variant?.product?.tags?.includes('out-of-stock');
  const slug = contentfulData?.slug;
  const { closeCartPopup, openConfirmWishlistPopup } = useUI();
  const [showOverlay, setShowOverlay] = useState(false);

  const createTrackingPayload = (item, productSelectListObj, isCompletelyRemoved = false) => ({
    name: item.title,
    id: parseShopifyGid(item.variant?.product.id),
    category: item.variant?.product.productType,
    price: item.variant?.priceV2.amount,
    quantity: isCompletelyRemoved ? item.quantity : 1,
    productSpecsCollection: contentfulData?.productSpecsCollection?.items || [],
    itemListName: productSelectListObj?.itemListName,
    currencyCode: cart?.currencyCode,
  });

  const {
    freeItem,
    amountOfProductsToGetFreeItem,
    promoCollectionName,
    shopifyTagsToExcludeFromPromo,
  } = globalModules?.secondpartsettings || {};

  const { freeitem: freeItemSettings, lineitem: lineitemModules } = globalModules || {};

  const relatedProducts = lineItem?.variant?.product?.relatedProducts?.value;
  const hasFreeItemTag = lineItem?.variant?.product?.tags?.includes('free-item');
  const hasFreeItemCustomAttribute = lineItem?.customAttributes?.find(
    attr => attr.key === '_freeItem'
  );

  const lineItemIsFreeItem =
    lineItem?.variant?.product?.id ===
    getShopifyGid('Product', freeItem?.product?.shopifyData?.variants[0]?.product_id);

  const getNewQuantity = (id, difference) => {
    if (!id) return 0;
    const currentQuantity = cart.lineItems?.edges?.reduce((acc, curr) => {
      if (curr.node.variant?.id === id) {
        return acc + curr.node.quantity;
      }
      return acc;
    }, 0);
    let newQuantity = currentQuantity - difference;
    if (newQuantity < 0) newQuantity = 0;

    return newQuantity;
  };

  const getFreeItemsQuantity = mainProductQuantityDifference => {
    if (!relatedProducts) return [];
    const parsedRelatedProducts = JSON.parse(relatedProducts);

    const newFreeItems = parsedRelatedProducts.map(id => ({
      variantId: id,
      quantity: getNewQuantity(id, mainProductQuantityDifference),
    }));

    return newFreeItems;
  };

  const handleRemoveProduct = async isKeyDown => {
    // If we are removing the free item from cart,
    // we disable it from being automatically added
    if (lineItemIsFreeItem) {
      disableFreeItem();
    }

    // todo investigate why is not working
    const items = [
      {
        variantId: lineItem.variant?.id,
        quantity: getNewQuantity(lineItem.variant?.id, lineItem.quantity),
      },
      ...getFreeItemsQuantity(lineItem.quantity),
    ];

    updateItemQuantity(items, freeItem?.product?.shopifyData, {
      amountOfProductsToGetFreeItem,
      promoCollectionName,
      shopifyTagsToExcludeFromPromo,
    });

    if (!lineItemIsFreeItem && lineItem?.variant) {
      const productSelectListObj = trackedItemsSelectList?.find(
        p => p?.productId === parseShopifyGid(lineItem?.variant?.product.id)
      );
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useProductRemoveFromCartTracking({
        product: createTrackingPayload(lineItem, productSelectListObj, true),
      });
    }

    if (!isKeyDown) {
      await removeProduct(lineItem?.variant?.product_id);
    }
  };

  const handleUpdateQuantity = value => {
    const items = [
      {
        variantId: lineItem.variant?.id,
        quantity: getNewQuantity(lineItem.variant?.id, lineItem.quantity - value),
      },
      ...getFreeItemsQuantity(lineItem.quantity - value),
    ];

    updateItemQuantity(items, freeItem?.product?.shopifyData, {
      amountOfProductsToGetFreeItem,
      promoCollectionName,
      shopifyTagsToExcludeFromPromo,
    });

    const productSelectListObj = trackedItemsSelectList?.find(
      p => p?.productId === parseShopifyGid(lineItem?.variant?.product?.id)
    );
    if (value > lineItem.quantity) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useProductAddToCartTracking({
        product: createTrackingPayload(lineItem, productSelectListObj),
      });
    } else if (value < lineItem.quantity) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useProductRemoveFromCartTracking({
        product: createTrackingPayload(lineItem, productSelectListObj),
      });
    }
  };

  const totalDiscount = lineItem.discountAllocations.reduce(
    (acc, curr) => acc + Number(curr.allocatedAmount.amount),
    0
  );

  const Prices = () => {
    const itemPrice = (Number(lineItem.variant?.priceV2?.amount) * lineItem.quantity).toFixed(2);
    const discountedPrice = totalDiscount ? Number(itemPrice - totalDiscount).toFixed(2) : null;

    const getPriceText = price => {
      if (price === '0.00' && freeItemSettings?.priceFreeText?.text) {
        return (
          <span style={{ color: freeItemSettings?.priceFreeTextColor?.text || '' }}>
            {freeItemSettings?.priceFreeText?.text}
          </span>
        );
      }
      return formatPriceByStoreLocale({
        locale,
        price,
      });
    };

    return (
      <span className={styles.price}>
        <span className={cn({ [styles.lineThrough]: discountedPrice })}>
          {getPriceText(itemPrice)}
        </span>
        {discountedPrice && (
          <span className={styles.discount}>{getPriceText(discountedPrice)}</span>
        )}
        <UnitPrice
          unitPrice={{
            ...(lineItem.variant?.unitPrice || {}),
            ...(lineItem.variant?.unitPriceMeasurement || {}),
          }}
          className={styles.unitPrice}
          locale={locale}
          size={contentfulData?.size}
          globalModules={globalModules}
        />
      </span>
    );
  };

  const LineItemImage = () => {
    const img = (
      <div className={cn(styles.itemImage, { [styles.smallItemImage]: isCartPopup || isMobile })}>
        <Image src={lineItem.variant?.image} layout="fill" />
      </div>
    );

    if (!slug) {
      return img;
    }
    return (
      <SiteLink
        locale={locale}
        href={addLocaleToHref(slug, locale, router)}
        onClick={closeCartPopup}
      >
        {img}
      </SiteLink>
    );
  };

  const RemoveButton = () => {
    if (hasFreeItemTag || lineItemIsFreeItem) {
      return null;
    }
    return (
      <span
        role="button"
        tabIndex={0}
        className={styles.actionButton}
        onClick={() => setShowOverlay(true)}
        onKeyDown={() => setShowOverlay(true)}
      >
        {lineitemModules?.removeText?.text}
      </span>
    );
  };

  const SaveForLaterButton = () => {
    if (hasFreeItemTag || lineItemIsFreeItem) {
      return null;
    }

    // if an item is already in wisglist, do not show the button
    if (localWishlist.includes(lineItem?.variant?.product?.id)) {
      return null;
    }

    const handleSaveForLater = async () => {
      const GIDs = [lineItem?.variant?.product?.id];
      await addToWishlist(GIDs);
      openConfirmWishlistPopup(lineItem);
      handleRemoveProduct();
    };

    return (
      <span
        role="button"
        tabIndex={0}
        className={styles.actionButton}
        onClick={() => handleSaveForLater()}
        onKeyDown={() => handleSaveForLater()}
      >
        {lineitemModules?.saveForLaterText?.text}
      </span>
    );
  };

  const ConfirmOverlay = () => {
    if (!showOverlay) return null;
    return (
      <div className={styles.confirmOverlay}>
        <span className={styles.overlayText}>{lineitemModules?.confirmRemoveMessage?.text}</span>
        <div className={styles.overlayButtons}>
          <button
            type="button"
            onClick={() => setShowOverlay(false)}
            className={styles.cancelButton}
          >
            {lineitemModules?.confirmRemoveNoButtonText?.text}
          </button>
          <button
            type="button"
            onClick={() => handleRemoveProduct()}
            className={styles.confirmButton}
          >
            {lineitemModules?.confirmRemoveYesButtonText?.text}
          </button>
        </div>
      </div>
    );
  };

  if (isCartPopup || isMobile) {
    return (
      <div className={styles.lineItemContainer}>
        <div className={cn(styles.popupLineItem, index !== 0 && styles.topBorder)}>
          <LineItemImage />
          <div className={styles.infoContainer}>
            <div className={styles.info}>
              <div>
                <div className={cn(styles.title, { [styles.opacTitle]: showOverlay })}>
                  {contentfulData?.title || lineItem.title}
                </div>
              </div>
              <Prices />
            </div>

            <div className={styles.controls}>
              <ItemCounter
                disabled={hasFreeItemTag || lineItemIsFreeItem || hasFreeItemCustomAttribute}
                onlyDecrease={false}
                quantity={lineItem.quantity}
                updateQuantity={value => handleUpdateQuantity(value)}
              />
              <span className={cn(styles.actionButtons, styles.rowActtionButtons)}>
                <RemoveButton />
                <SaveForLaterButton />
              </span>
            </div>
          </div>
        </div>
        <ConfirmOverlay />
      </div>
    );
  }

  return (
    <div className={styles.lineItemContainer}>
      <div className={cn(styles.lineItem, index !== 0 && styles.topBorder, styles.lineWidth)}>
        <LineItemImage />
        <div className={cn(styles.info, styles.columnWidthItem)}>
          <div className={cn(styles.infoBox, styles.columnWidth)}>
            <span className={styles.title}>{contentfulData?.title || lineItem.title}</span>
            <span className={cn(styles.actionButtons, styles.rowActtionButtons)}>
              <RemoveButton />
              <SaveForLaterButton />
            </span>
          </div>
        </div>
        <div className={styles.columnWidth}>{lineItem.variant ? <Prices /> : <span>-</span>}</div>

        <div className={cn(styles.controls, styles.columnWidth)}>
          {outOfStock ? (
            <div className={styles.outOfStockTag}>
              {globalModules?.productpage?.outOfStock?.text}
            </div>
          ) : (
            <ItemCounter
              disabled={hasFreeItemTag || lineItemIsFreeItem || hasFreeItemCustomAttribute}
              onlyDecrease={false}
              quantity={lineItem.quantity}
              updateQuantity={value => handleUpdateQuantity(value)}
            />
          )}
        </div>
      </div>
      <ConfirmOverlay />
    </div>
  );
};

export default LineItem;

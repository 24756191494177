import Icon from 'components/shared/icon';
import Image from 'components/shared/image';
import { useUI } from 'hooks';
import styles from './confirmWishlistPopup.module.scss';

const ConfirmWishlistPopup = ({ globalModules }) => {
  const { message, closeButtonText } = globalModules?.confirmwishlistpopup || {};
  const { confirmWishlistData, displayConfirmWishlistPopup, closeConfirmWishlistPopup } = useUI();

  if (!displayConfirmWishlistPopup) return null;
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <button
          type="button"
          className={styles.closeBtn}
          onClick={() => {
            closeConfirmWishlistPopup();
          }}
        >
          <Icon type="close" />
        </button>
        <div className={styles.message}>{message?.text}</div>
        <div className={styles.productInfo}>
          <div className={styles.itemImage}>
            <Image src={confirmWishlistData?.variant?.image} layout="fill" />
          </div>
          <span className={styles.productTitle}>{confirmWishlistData?.title}</span>
        </div>
        <div className={styles.buttons}>
          <button
            type="button"
            className={styles.button}
            onClick={() => closeConfirmWishlistPopup()}
          >
            {closeButtonText?.text}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmWishlistPopup;
